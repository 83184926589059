import {
  createContext,
  forwardRef,
  useContext,
  useEffect,
  useState,
} from "react";
import Image from "next/image";
import * as Select from "@radix-ui/react-select";
import { CheckIcon, ChevronDownIcon } from "lucide-react";
import { cn } from "../../utils/tailwind";

export type GamePlayMode =
  | "seasonal_hardcore"
  | "seasonal_softcore"
  | "eternal_softcore"
  | "eternal_hardcore";

export type GamplayModeOption = {
  value: GamePlayMode;
  label: string;
  thumbnailUrl: string;
};

type ContextType = {
  options: GamplayModeOption[];
  value: GamePlayMode;
};

const Context = createContext({} as ContextType);

export function GameplayModeSelect({
  options,
  value,
  onValueChange,
  ...rest
}: Omit<Select.SelectProps, "value" | "defaultValue" | "onValueChange"> & {
  value: GamePlayMode;
  defaultValue?: GamePlayMode;
  onValueChange?: (value: GamePlayMode) => void;
  options: GamplayModeOption[];
}) {
  const [internalValue, setInternalValue] = useState<GamePlayMode>();

  useEffect(() => {
    setInternalValue(value);
  }, [value]);

  return (
    <Context.Provider
      value={{
        options,
        value,
      }}
    >
      <Select.Root
        {...rest}
        value={internalValue}
        onValueChange={(value: GamePlayMode) => {
          setInternalValue(value);
          onValueChange?.(value);
        }}
      />
    </Context.Provider>
  );
}

GameplayModeSelect.Trigger = Trigger;
GameplayModeSelect.Content = Content;
GameplayModeSelect.Item = Item;

function Trigger({ className, ...rest }: Select.SelectTriggerProps) {
  const { options, value } = useContext(Context);
  const thumbnailUrl = options.find(
    (option) => option.value === value
  )?.thumbnailUrl;

  return (
    <Select.Trigger
      className={cn(
        "ui-flex ui-h-8 ui-w-full ui-items-center ui-justify-between ui-text-gray-50 ui-bg-gray-500 ui-px-2",
        className
      )}
      {...rest}
    >
      <span className="ui-flex ui-items-center">
        {value && thumbnailUrl && (
          <span className="ui-flex ui-h-[16px] ui-w-[20px] ui-ml-2">
            <Image alt="" height={20} src={thumbnailUrl} width={16} />
          </span>
        )}

        <span className="ui-flex-1 ui-truncate ui-text-inherit ui-px-1 ui-text-left">
          <Select.Value placeholder="Select" />
        </span>
      </span>
      <Select.Icon className="ui-ml-2 ui-transition-transform ui-duration-200">
        <ChevronDownIcon className="ui-h-4 ui-w-4" />
      </Select.Icon>
    </Select.Trigger>
  );
}

function Content({ children, className, ...rest }: Select.SelectContentProps) {
  return (
    <Select.Portal>
      <Select.Content
        {...rest}
        className={cn(
          "ui-relative ui-z-50 ui-max-h-96 ui-min-w-[8rem] ui-overflow-hidden ui-rounded-sm ui-bg-gray-600 ui-shadow-lg ui-data-[side=bottom]:ui-translate-y-1 ui-data-[side=left]:-ui-translate-x-1 ui-data-[side=right]:ui-translate-x-1 ui-data-[side=top]:-ui-translate-y-1",
          className
        )}
        position="popper"
      >
        <Select.Viewport className="ui-h-[var(--radix-select-trigger-height)] ui-w-full ui-min-w-[var(--radix-select-trigger-width)] ui-p-1">
          {children}
        </Select.Viewport>
      </Select.Content>
    </Select.Portal>
  );
}

function Item({
  className,
  children,
  value,
  label,
  thumbnailUrl,
  ...rest
}: React.HTMLAttributes<HTMLElement> & GamplayModeOption) {
  return (
    <Select.Item
      {...rest}
      value={value}
      className={cn(
        "ui-relative ui-flex ui-h-8 ui-w-full ui-text-gray-50 ui-min-w-[var(--radix-select-trigger-width)] ui-cursor-default ui-items-center ui-gap-2 ui-rounded-sm ui-px-2 ui-py-1.5 ui-pr-8  ui-hover:ui-bg-gray-700",
        className
      )}
    >
      <Select.ItemIndicator className="ui-absolute ui-right-2 ui-h-4 ui-w-4">
        <CheckIcon className="ui-h-4 ui-w-4" />
      </Select.ItemIndicator>
      <Image alt={label} height={20} src={thumbnailUrl} width={16} />
      <Select.ItemText>{children}</Select.ItemText>
    </Select.Item>
  );
}

GameplayModeSelect.displayName = "GameplayModeSelect";
