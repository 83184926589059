"use client";

import { forwardRef } from "react";
import { Check, ChevronDown, ChevronUp } from "lucide-react";
import * as Primitive from "@radix-ui/react-select";
import { cn } from "../../utils/tailwind";

export function Select(props: Primitive.SelectProps) {
  return <Primitive.Root {...props} />;
}

Select.Trigger = forwardRef(Trigger);
Select.Value = forwardRef(Value);
Select.Content = forwardRef(Content);
Select.Label = forwardRef(Label);
Select.Item = forwardRef(Item);
Select.Separator = forwardRef(Separator);
Select.Group = Primitive.Group;
Select.Icon = Icon;

function Trigger(
  {
    className,
    children,
    icon,
    ...props
  }: React.ComponentPropsWithoutRef<typeof Primitive.Trigger> & {
    icon?: React.ReactNode;
  },
  ref: React.ForwardedRef<React.ElementRef<typeof Primitive.Trigger>>
) {
  return (
    <Primitive.Trigger
      className={cn(
        "ui-flex ui-h-8 ui-w-full ui-items-center ui-justify-between ui-bg-gray-500 ui-px-2  ui-text-gray-50",
        className
      )}
      ref={ref}
      {...props}
    >
      {children}
      {icon || <Icon />}
    </Primitive.Trigger>
  );
}

function Value(
  props: React.ComponentPropsWithoutRef<typeof Primitive.Value>,
  ref: React.ForwardedRef<React.ElementRef<typeof Primitive.Value>>
) {
  return (
    <span className="ui-flex ui-truncate ui-px-1 ui-focus:outline-none">
      <Primitive.Value ref={ref} placeholder="Select" {...props} />
    </span>
  );
}

function ScrollUpButton(
  {
    className,
    ...props
  }: React.ComponentPropsWithoutRef<typeof Primitive.ScrollUpButton>,
  ref: React.ForwardedRef<React.ElementRef<typeof Primitive.ScrollUpButton>>
) {
  return (
    <Primitive.ScrollUpButton
      className={cn(
        "ui-flex ui-cursor-default ui-items-center ui-justify-center ui-py-1",
        className
      )}
      ref={ref}
      {...props}
    >
      <ChevronUp className="h-4 w-4" />
    </Primitive.ScrollUpButton>
  );
}

function ScrollDownButton(
  {
    className,
    ...props
  }: React.ComponentPropsWithoutRef<typeof Primitive.ScrollDownButton>,
  ref: React.ForwardedRef<React.ElementRef<typeof Primitive.ScrollDownButton>>
) {
  return (
    <Primitive.ScrollDownButton
      className={cn(
        "ui-flex ui-cursor-default ui-items-center ui-justify-center ui-py-1",
        className
      )}
      ref={ref}
      {...props}
    >
      <ChevronDown className="h-4 w-4" />
    </Primitive.ScrollDownButton>
  );
}

function Content(
  {
    className,
    children,
    position = "popper",
    ...props
  }: React.ComponentPropsWithoutRef<typeof Primitive.Content>,
  ref: React.ForwardedRef<React.ElementRef<typeof Primitive.Content>>
) {
  return (
    <Primitive.Portal>
      <Primitive.Content
        className={cn(
          "ui-relative ui-z-50 ui-max-h-96 ui-min-w-[8rem] ui-overflow-hidden ui-rounded-sm ui-bg-gray-600 ui-shadow-lg",
          position === "popper" &&
            "ui-data-[side=bottom]:translate-y-1 ui-data-[side=left]:-translate-x-1 ui-data-[side=right]:translate-x-1 ui-data-[side=top]:-translate-y-1",
          className
        )}
        position={position}
        ref={ref}
        {...props}
      >
        <ScrollUpButton />
        <Primitive.Viewport
          className={cn(
            "ui-p-1",
            position === "popper" &&
              "ui-h-[var(--radix-select-trigger-height)] ui-w-full ui-min-w-[var(--radix-select-trigger-width)]"
          )}
        >
          {children}
        </Primitive.Viewport>
        <ScrollDownButton />
      </Primitive.Content>
    </Primitive.Portal>
  );
}

function Label(
  {
    className,
    ...props
  }: React.ComponentPropsWithoutRef<typeof Primitive.Label>,
  ref: React.ForwardedRef<React.ElementRef<typeof Primitive.Label>>
) {
  return (
    <Primitive.Label
      {...props}
      className={cn(
        "ui-py-1.5 ui-pl-8 ui-pr-2 ui-text-sm ui-font-semibold",
        className
      )}
      ref={ref}
    />
  );
}

function Item(
  {
    className,
    children,
    ...props
  }: React.ComponentPropsWithoutRef<typeof Primitive.Item>,
  ref: React.ForwardedRef<React.ElementRef<typeof Primitive.Item>>
) {
  return (
    <Primitive.Item
      className={cn(
        "ui-relative ui-flex ui-h-8 ui-w-full ui-cursor-default ui-items-center ui-text-gray-50  ui-rounded-sm ui-py-1.5 ui-pl-8 ui-pr-2 ui-text-sm ui-outline-none hover:ui-bg-gray-700",
        className
      )}
      ref={ref}
      {...props}
    >
      <span className="ui-absolute ui-left-2 ui-flex ui-h-3.5 ui-w-3.5 ui-items-center ui-justify-center">
        <Primitive.ItemIndicator>
          <Check className="ui-h-4 ui-w-4" />
        </Primitive.ItemIndicator>
      </span>

      <Primitive.ItemText>{children}</Primitive.ItemText>
    </Primitive.Item>
  );
}

function Separator(
  {
    className,
    children,
    ...props
  }: React.ComponentPropsWithoutRef<typeof Primitive.Separator>,
  ref: React.ForwardedRef<React.ElementRef<typeof Primitive.Separator>>
) {
  return (
    <Primitive.Separator
      className={cn("ui--mx-1 ui-my-1 ui-h-px", className)}
      ref={ref}
      {...props}
    />
  );
}

function Icon({
  children,
  ...rest
}: Primitive.SelectIconProps & {
  animated?: boolean;
}) {
  return (
    <Primitive.Icon {...rest}>
      {children || <ChevronDown className="ui-h-4 ui-w-4" />}
    </Primitive.Icon>
  );
}
